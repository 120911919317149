import React, { useEffect } from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from 'react-redux'
import reportWebVitals from './reportWebVitals'

import { injectStore } from './services/api/config/axiosConfig'
import configureAppStore from './store'

import 'assets/scss/style.scss'
import App from './App'
import Loader from 'ui-component/Loader'

const { store, persistor } = configureAppStore()
injectStore(store)

function AppWithCallbackAfterRender() {
  useEffect(() => {
  }, [])

  return (
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate loading={<Loader/>} persistor={persistor}>
          <BrowserRouter>
            <App/>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </React.StrictMode>
  )
}

const container = document.getElementById('root')
const root = createRoot(container)
root.render(<AppWithCallbackAfterRender/>)
reportWebVitals()
