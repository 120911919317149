import { CLEAR_CUSTOMIZATION } from 'store/actions'
import { clearUser } from 'store/reducers/userReducer'
import getErrorMessage from 'utils/apiExceptionUtil'

/**
 * Clear data reducers
 */
export default function handlerLogOut() {
  return async function handlerLogOutThunk(dispatch) {
    try {
      dispatch({ type: CLEAR_CUSTOMIZATION })
      dispatch(clearUser())
      localStorage.clear()
    } catch (ex) {
      console.error('Error handler logOut', ex.message)
     throw new Error(getErrorMessage(ex))
    }
  }
}
