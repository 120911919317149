import { lazy } from 'react'

import MainLayout from 'layout/MainLayout'
import Loadable from 'ui-component/Loadable'

const Home = Loadable(lazy(() => import('pages/Home/index')))
const FlipCards = Loadable(lazy(() => import('pages/FlipCards/FlipCards')))

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: <MainLayout/>,
  children: [
    {
      path: '/home',
      element: <FlipCards/>
    },
    {
      path: '/notes',
      element: <Home/>
    }
  ]
}

export default MainRoutes
