import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import customizationReducer from './customizationReducer'
import userReducer from './userReducer'
import dialogReducer from './dialogReducer'

const persistConfig = {
  key: 'root',
  rehydrated: true,
  version: 3,
  storage
  // blacklist: ['customization'],
  // stateReconciler: hardSet,
  // whitelist: ['customization', 'user']
// whitelist: ['user', 'ploy', 'realties', 'realtiesIndividual']
}


const reducer = combineReducers({
  customization: customizationReducer,
  user: userReducer,
  dialog: dialogReducer
})

export default persistReducer(persistConfig, reducer)
