import Routes from 'routes'
import themes from 'themes'
import { ThemeProvider } from '@mui/material/styles'
import { CssBaseline, StyledEngineProvider } from '@mui/material'
import NavigationScroll from './layout/NavigationScroll'
import { useSelector } from 'react-redux'
import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'


function App() {
  const { customization } = useSelector((state) => state)
  const user = useSelector((state) => state.user)
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if ((user === null || !user.isLogged) && location.pathname !== '/' && location.pathname !== '/pages/register') {
      navigate('/', { replace: true })
    }
  }, [])

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes(customization)}>
        <CssBaseline/>
        <NavigationScroll>
          <Routes/>
        </NavigationScroll>
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

export default App
