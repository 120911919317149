import DashboardIcon from '@mui/icons-material/Dashboard'

const icons = { DashboardIcon }

const dashboard = {
  id: 'home',
  title: 'Página Inicial',
  type: 'group',
  children: [
    {
      id: 'default',
      title: 'Home',
      type: 'item',
      url: '/home',
      icon: icons.DashboardIcon,
      breadcrumbs: false
    }
  ]
}

export default dashboard
