import { Outlet } from 'react-router-dom'
import ResponsiveDialog from 'ui-component/dialogs/ResponsiveDialog'

// ==============================|| MINIMAL LAYOUT ||============================== //

const MinimalLayout = () => (
  <>
    <Outlet/>
    <ResponsiveDialog/>
  </>
)

export default MinimalLayout
