/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */
import logo from 'assets/images/logo.webp';
// ==============================|| LOGO SVG ||============================== //

export default function Logo() {
  // return <img src={logo} alt="logo" width="150" height="50" />
}
