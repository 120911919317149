import axios from 'axios'
import { getAuthCode } from './apiService'
import handlerLogOut from 'services/logOutService'

let store

export const injectStore = _store => {
  store = _store
}

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 60000,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'x-api-key': process.env.REACT_APP_X_API_KEY,
    'Access-Control-Allow-Origin': '*',
    'X-COMPANY-CODE': process.env.REACT_APP_X_COMPANY_CODE
  }
})

const errorHandler = (error) => {

  if (error?.code === 'ERR_CANCELED') {
    return Promise.resolve()
  }

  const status = error?.status || error?.response?.status

 if (status === 400 && error?.response?.data != null) {
    const message = error?.response?.data.message
    return Promise.reject(new Error(message))
  }

  if (status === 403 || status === 401 || status == null) {
    const { dispatch } = store
    dispatch(handlerLogOut())
    return Promise.resolve()
  }

  const httpErrorInfo = {
    status: error?.status,
    statusText: error?.statusText,
    url: error?.url
  }
  console.debug(`logging http details for debugging: ${JSON.stringify(httpErrorInfo)}`)
  return Promise.reject(new Error(JSON.stringify(httpErrorInfo)))
}

function requestHandler(request) {
  request.headers.Authorization = getAuthCode(store.getState())
  return request
}

api.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => errorHandler(error)
)

api.interceptors.response.use(
  undefined,
  (error) => errorHandler(error)
)

export default api
