import dashboard from './dashboard'
import manager from './manager'

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [dashboard]
}

export default menuItems
