import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLogged: false,
  isFetching: false
}

const userSlice = createSlice({
  name: '@user',
  initialState,
  reducers: {
    setUser: (state, action) => ({ ...state, ...action.payload }),
    setLogged: (state) => ({ ...state, isLogged: true }),
    clearUser: () => initialState
  }
})

export const { setUser, clearUser, setLogged } = userSlice.actions
export default userSlice.reducer
