import PropTypes from 'prop-types'

// material-ui
import { useTheme } from '@mui/material/styles'
import { Avatar, Box, ButtonBase } from '@mui/material'

// project imports
import LogoSection from '../LogoSection'
// import ProfileSection from './ProfileSection'
// import NotificationSection from './NotificationSection'

// assets
import MenuIcon from '@mui/icons-material/Menu'
import ProfileSection from './ProfileSection'

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({ drawerOpen, handleLeftDrawerToggle }) => {
  const theme = useTheme()

  return (
    <>
      <Box
        className="test_Home_HEADER"
        sx={{
          width: 228,
          display: 'flex',
          [theme.breakpoints.down('md')]: {
            width: 'auto'
          }
        }}
      >
        {/*<Box component="span" sx={{ display: { xs: 'none', md: 'flex' }, flexGrow: 1 }}>*/}
        {/*  <LogoSection/>*/}
        {/*</Box>*/}
        <ButtonBase sx={{ borderRadius: '10px', overflow: 'hidden' }}>
          <Avatar
            variant="rounded"
            sx={{
              ...theme.typography.commonAvatar,
              ...theme.typography.mediumAvatar,
              transition: 'all .2s ease-in-out',
              background: drawerOpen ? theme.palette.secondary.dark : theme.palette.background.default,
              color: drawerOpen ? theme.palette.primary.dark : theme.palette.text.primary,
              '&:hover': {
                background: theme.palette.secondary.dark,
                color: theme.palette.primary.dark
              }
            }}
            onClick={handleLeftDrawerToggle}
            color="inherit"
          >
            <MenuIcon size="1rem"/>
          </Avatar>
        </ButtonBase>
      </Box>
      <Box sx={{ flexGrow: 1 }}/>
      <Box sx={{ flexGrow: 1 }}/>

      {/*<NotificationSection/>*/}
      <ProfileSection/>
    </>
  )
}

Header.propTypes = {
  handleLeftDrawerToggle: PropTypes.func,
  drawerOpen: PropTypes.bool
}

export default Header
