import { useRoutes } from 'react-router-dom'
import AuthenticationRoutes from './AuthenticationRoutes'
import MainRoutes from './MainRoutes'
import config from 'config'
import { useSelector } from 'react-redux'
// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  const user = useSelector((state) => state.user)
  const routes = [AuthenticationRoutes]
  if (user?.isLogged) {
    routes.push(MainRoutes)
  }
  return useRoutes(routes, config.basename)
}
