const config = {
  basename: '',
  defaultPath: '/home',
  fontFamily: `'Roboto', sans-serif`,
  presetColor: 'default',
  borderRadius: 12,
  container: true,
  menuOrientation: 'vertical'
}

export default config
