import { createTheme } from '@mui/material/styles'
import * as locales from '@mui/material/locale'
import { ptBR } from '@mui/material/locale'
// assets
import colors from 'assets/scss/_themes-vars.module.scss'

// project imports
import componentStyleOverrides from './compStyleOverride'
import themePalette from './palette'
import themeTypography from './typography'
import darkPalette from './darkPalette'


function getThemeOption(customization) {
  const color = colors

  if (customization.themeColor === 'dark') {
    return {
      colors: color,
      heading: color.darkTextTitle,
      paper: color.darkBackground,
      backgroundDefault: color.darkPaper,
      background: color.darkBackground,
      darkTextPrimary: color.darkTextPrimary,
      darkTextSecondary: color.darkTextSecondary,
      textDark: color.grey900,
      menuSelected: color.darkSecondaryMain,
      menuSelectedBack: color.darkLevel2,
      divider: color.darkLevel1,
      customization
    }
  }

  return {
    colors: color,
    heading: color.grey900,
    paper: color.paper,
    backgroundDefault: color.paper,
    background: color.grey100,
    darkTextPrimary: color.grey700,
    darkTextSecondary: color.grey500,
    textDark: color.grey900,
    menuSelected: color.secondaryDark,
    menuSelectedBack: color.secondaryLight,
    divider: color.grey200,
    customization
  }
}


export const theme = (customization) => {

  const themeOption = getThemeOption(customization)
  const palette = customization.themeColor === 'dark' ? darkPalette(themeOption) : themePalette(themeOption)
  const themeOptions = {
    direction: 'ltr',
    palette: palette,
    mixins: {
      toolbar: {
        minHeight: '48px',
        padding: '16px',
        '@media (min-width: 600px)': {
          minHeight: '48px'
        }
      }
    },
    typography: themeTypography(themeOption)
  }

  const themes = createTheme(themeOptions, locales[ptBR])
  themes.components = componentStyleOverrides(themeOption)

  return themes
}

export default theme
