import * as React from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import { CssBaseline, useMediaQuery } from '@mui/material'
import { styled, useTheme } from '@mui/material/styles'
import Sidebar from './Sidebar'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import Header from './Header'
import { useDispatch, useSelector } from 'react-redux'
import { SET_MENU } from '../../store/actions'
import { useEffect } from 'react'
import ResponsiveDialog from 'ui-component/dialogs/ResponsiveDialog'

export const drawerWidth = 260


// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme: themes, open }) => ({
  ...themes.typography.mainContent,
  ...(!open && {
    borderRadius: '12px',
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    marginTop: '70px',
    transition: themes.transitions.create('margin', {
      easing: themes.transitions.easing.sharp,
      duration: themes.transitions.duration.leavingScreen
    }),
    [themes.breakpoints.up('md')]: {
      marginLeft: -(drawerWidth - 20),
      width: `calc(100% - ${drawerWidth}px)`
    },
    [themes.breakpoints.down('md')]: {
      marginLeft: '20px',
      width: `calc(100% - ${drawerWidth}px)`,
      padding: '16px'
    },
    [themes.breakpoints.down('sm')]: {
      marginLeft: '10px',
      width: `calc(100% - ${drawerWidth}px)`,
      padding: '16px',
      marginRight: '10px'
    }
  }),
  ...(open && {
    transition: themes.transitions.create('margin', {
      easing: themes.transitions.easing.easeOut,
      duration: themes.transitions.duration.enteringScreen
    }),
    marginLeft: 0,
    marginTop: '70px',
    borderRadius: '12px',
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    width: `calc(100% - ${drawerWidth}px)`,
    [themes.breakpoints.down('md')]: {
      marginLeft: '20px'
    },
    [themes.breakpoints.down('sm')]: {
      marginLeft: '10px',
      marginRight: '10px',
      padding: '16px'
    }
  })
}))


function MainLayout() {

  const theme = useTheme()
  const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'))
  const location = useLocation()
  const navigate = useNavigate()

  const user = useSelector((state) => state.user)
  const leftDrawerOpened = useSelector((state) => state.customization.opened)
  const dispatch = useDispatch()

  useEffect(() => {
    if ((user === null || !user.isLogged) && location.pathname !== '/') {
      navigate('/', { replace: true })
    }
  }, [user, navigate, location.pathname])

  useEffect(() => {
    dispatch({ type: SET_MENU, opened: !matchDownMd })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchDownMd])

  function handleLeftDrawerToggle() {
    dispatch({ type: SET_MENU, opened: !leftDrawerOpened })
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline/>
      <AppBar
        enableColorOnDark
        position="fixed"
        color="inherit"
        elevation={0}
        sx={{
          borderTop: '0.5px solid',
          borderBottom: '0.5px solid',
          borderColor: theme.palette.primary.light,
          bgcolor: theme.palette.background.default,
          transition: leftDrawerOpened ? theme.transitions.create('width') : 'none'
        }}
      >
        <Toolbar>
          <Header drawerOpen={leftDrawerOpened} handleLeftDrawerToggle={handleLeftDrawerToggle}/>
        </Toolbar>
      </AppBar>
      <Sidebar drawerOpen={leftDrawerOpened} drawerToggle={() => handleLeftDrawerToggle()}/>
      <Main themes={theme} open={leftDrawerOpened}>
        <Outlet/>
        <ResponsiveDialog/>
      </Main>
    </Box>
  )
}

export default MainLayout
